
import { SfModal, SfButton, SfHeading } from '@storefront-ui/vue';
import { defineComponent, computed } from 'vue';
import { getInterestingCategoryImagePath } from 'theme/helpers'

export default defineComponent({
  name: 'OModalInterestingCategories',
  components: {
    SfModal,
    SfButton,
    SfHeading
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const closeModal = () => {
      emit('close', props.modalData.name)
    }
    const isVisible = computed(() => props.isVisible)
    const modalData = computed(() => props.modalData)
    const categories = computed(() => props.modalData?.payload?.categories || [])
    const sortedCategories = computed(() => {
      if (!categories.value?.length) return []
      const sorted = [...categories.value].sort((a, b) => a.position - b.position)
      return sorted
    })

    const getImgPath = (relativePath: string) => {
      return getInterestingCategoryImagePath(relativePath)
    }

    return {
      isVisible,
      closeModal,
      modalData,
      sortedCategories,
      getImgPath
    }
  }
})
